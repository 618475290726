import * as React from "react";

const Footer = () => {
  return (
    <footer class="bg-white py-3 border-t border-gray-100">
      <div class="text-center md:text-left py-5 max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div class="">
          <p class="pb-2 text-base text-gray-400">Get In Touch</p>
          <h1 class="text-4xl tracking-wide font-bold text-primaryBlue">
            <a class="hover:underline" href="tel:8883417735">
              {" "}
              888.341.7735
            </a>
          </h1>
          <h1 class="mt-3 mb-10 md:mb-0 md:text-3xl tracking-wide font-medium text-teal-500">
            <a class="hover:underline" href="mailto:marketing@consultfgc.com">
              {" "}
              marketing@consultfgc.com
            </a>
          </h1>
        </div>
        <div class="flex items-center justify-center">
          <div class="relative">
            {" "}
            <a
              class="w-2/3 block hover:opacity-70"
              href="https://www.google.com/partners/agency?id=4727175905"
              target="_blank"
            >
              <img
                class=""
                src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg"
              />
            </a>
          </div>
          <div class=" -ml-6 relative">
            {" "}
            <a
              class="hover:opacity-70"
              href="https://gogreencredits.com/"
              target="_blank"
            >
              <img
                class="w-24 block"
                src="https://imgix.cosmicjs.com/d7d9c380-f224-11ec-a2eb-c1653f3f9199-Circle-badge.svg"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="copyright border-gray-100 py-5  border-t max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div class="flex justify-center space-x-6 md:order-2">
          <a
            href="https://www.facebook.com/ConsultFGC/"
            class="text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">Facebook</span>
            <svg
              class="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/consult_fgc"
            class="text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">Instagram</span>
            <svg
              class="h-5 w-5 mt-0.5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                clip-rule="evenodd"
              />
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/consult-fgc/"
            class="text-gray-400 hover:text-gray-500"
          >
            <span class="sr-only">Linkedin</span>
            <svg
              class="h-6 w-6 pt-0.5 pb-0.5"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>

        <div class="mt-8 md:mt-0 md:order-1">
          <p class="text-center text-base text-gray-400">
            &copy; {new Date().getFullYear()} ConsultFGC,
            <a class="text-blue-400 hover:underline" href="/capabilities">
              <span> GSA Capabilities</span>
            </a>{" "}
          </p>
        </div>
      </div>

      <div class="text-center space-x-2 md:text-left max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center lg:px-8">
        <a href="/terms-of-service">
          <span class="text-gray-400 hover:text-blue-400 hover:underline">
            Terms and Conditions
          </span>
        </a>
        <a href="/ccpa">
          <span class="text-gray-400 hover:text-blue-400 hover:underline">
            {" "}
            CCPA
          </span>
        </a>
        <a href="/privacy-policy">
          <span class="text-gray-400 hover:text-blue-400 hover:underline">
            {" "}
            Privacy Policy{" "}
          </span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
//
